import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
import styled from "styled-components"
import HeaderImg from "../images/header-img.jpg"
import HTWFBook from "../images/htwf.png"
import Radu from "../images/radu-profile.jpg"

// import Ill1 from "../images/illustration1.jpg"
// import Ill2 from "../images/illustration2.jpg"
// import Ill3 from "../images/illustration3.jpg"
// import Ill4 from "../images/illustration4.jpg"

import Book1 from "../images/book01.jpg"
import Book2 from "../images/book02.jpg"
import Book3 from "../images/book03.jpg"
import Book4 from "../images/book04.jpg"

import Phone from "../images/phone2.png"

import Layout from "../components/layout"

export const query = graphql`
  query {
    allStrapiBook {
      edges {
        node {
          strapiId
          title
          author
          image
          link
          summary
          audience
        }
      }
    }
  }
`
const Home = ({ data }) => {
  const featBook = data.allStrapiBook.edges.find(
    book => book.node.strapiId === "601ff703a3e3330643901a42"
  ).node

  const colors = [
    "green",
    "teal",
    "yellow",
    "red",
    "blue",
    "purple",
    "green",
    "teal",
    "yellow",
    "red",
    "blue",
    "purple",
  ]
  return (
    <Layout>
      <Seo title={"Books Made Actionable"} />
      <section
        className="hero is-medium is-relative"
        style={{ overflowY: "hidden" }}
      >
        <div className="hero-body">
          <div className="container pb-6">
            <div className="columns is-top">
              <div className="column is-6 mt-6">
                <h1 className="is-size-2 has-text-weight-bold title-font">
                  Books Made Actionable. Convert Knowledge Into{" "}
                  <span className="has-text-success">Skills</span> and{" "}
                  <span className="has-text-danger">Habits</span>.
                </h1>
                <h2 className="is-size-6 mt-4">
                  We curate books into actionable bite-sized ideas to instantly
                  change how you work, live, and think.
                </h2>
                <Link to="/browse" className="button is-success mt-3">
                  Browse catalog
                </Link>
              </div>
            </div>
          </div>
        </div>
        <StyledHeaderImg src={HeaderImg} alt="" className="is-absolute" />
      </section>

      <div className="has-background-dark pt-6 pb-6">
        <section className="section">
          <div className="container">
            {/* <div className="columns">
                    <div className="column is-8">
                      <p className="has-text-white is-size-5 title-font">
                        Featured book
                      </p>
                      <ArticlesComponent
                        articles={data.allStrapiArticle.edges}
                        defaultSort="best"
                        limit="2"
                      />
                    </div>
                  </div> */}
            <div className="columns is-relative" style={{ zIndex: "2" }}>
              <div className="column is-4">
                <Link to={featBook.title.replace(/\s+/g, "-").toLowerCase()}>
                  <img src={HTWFBook} alt="" />
                </Link>
              </div>
              <div className="column is-8">
                <p className="has-opacity-65 has-text-white ">Dale Carnegie</p>
                <p className="is-size-3 has-text-white has-text-weight-bold title-font">
                  How To Win Friends & Influence People
                </p>
                <div className="content mt-5 mb-6">
                  {featBook.summary
                    ? featBook.summary.split("\n").map((book, index) => {
                        return (
                          <p className="has-text-white is-block" key={index}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="is-24 top-6px mr-2"
                              viewBox="0 0 50 50"
                              fill="#e96463"
                            >
                              <path
                                d="M25,2C12.318,2,2,12.318,2,25c0,12.683,10.318,23,23,23c12.683,0,23-10.317,23-23C48,12.318,37.683,2,25,2z M35.827,16.562 L24.316,33.525l-8.997-8.349c-0.405-0.375-0.429-1.008-0.053-1.413c0.375-0.406,1.009-0.428,1.413-0.053l7.29,6.764l10.203-15.036 c0.311-0.457,0.933-0.575,1.389-0.266C36.019,15.482,36.138,16.104,35.827,16.562z"
                                fill="#e96463"
                              />
                            </svg>
                            {book}
                          </p>
                        )
                      })
                    : ""}
                </div>
                <Link
                  to={featBook.title.replace(/\s+/g, "-").toLowerCase()}
                  className="button is-danger"
                >
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="is-relative mb-6" style={{ backgroundColor: "#11183A" }}>
        <WaveDetail
          style={{
            top: "-100px",
            bottom: "auto",
            transform: "rotate(180deg)",
          }}
        />
        <section
          className="section is-paddingless is-relative"
          style={{ zIndex: "2" }}
        >
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-6 has-text-centered">
                <figure
                  className="image is-128x128 mb-4"
                  style={{ margin: "auto" }}
                >
                  <img
                    className="is-rounded"
                    src={"https://images.pexels.com/photos/839011/pexels-photo-839011.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200"}
                    style={{ objectFit: "cover", height: "100%" }}
                  />
                </figure>
                <p className="has-text-white is-size-5">
                  “An amazing experience! Would totally recommend this to anyone
                  struggling to apply what they read in books like this!”
                </p>
              </div>
            </div>
          </div>
        </section>
        <WaveDetail />
      </div>
      <div className="section"></div>
      <div className="section">
        <div className="container">
          <div className="columns mb-6">
            <div className="column is-5">
              <p className="title-font is-size-2">
                Just reading good books won’t do the trick...
              </p>
              <p className="mt-3">
                We built BookDood with a specific use case in mind. If any of
                the following apply to you, you are one of us and BookDood will
                change your life for the better.
              </p>
            </div>
          </div>
          <div className="columns is-multiline">
            {featBook.audience
              ? featBook.audience.split("\n").map((audienceItem, index) => {
                  return (
                    <div className="column is-4" key={index}>
                      <p className="pr-3 who-item is-relative mb-6">
                        <span
                          className={"has-background-" + colors[index] + "-100"}
                        ></span>
                        {audienceItem}
                      </p>
                    </div>
                  )
                })
              : ""}
            {/* <div className="column is-4 mb-6 has-text-centered">
              <img src={Ill1} width="240px" alt="" />
              <p className="pr-6">
                Passive learning isn’t your thing and you wish for a more
                hands-on learning experience.
              </p>
            </div>
            <div className="column is-4 mb-6 has-text-centered">
              <img src={Ill2} width="240px" alt="" />
              <p className="pr-6">
                You struggle to apply the lessons that are taught in the books
                you read.
              </p>
            </div>
            <div className="column is-4 mb-6 has-text-centered">
              <img src={Ill3} width="240px" alt="" />
              <p className="pr-6">
                You find it difficult to spark conversations and share what you
                learned from a book.
              </p>
            </div>
            <div className="column is-4 mb-6 has-text-centered">
              <img src={Ill4} width="240px" alt="" />
              <p className="pr-6">
                You read books that share many valuable insights, but no
                concrete way to put them into action.
              </p>
            </div> */}
          </div>
        </div>
      </div>

      <div className="is-relative">
        <div className="clip"></div>
        <div className="has-background-dark">
          <section className="section">
            <div className="container">
              <div
                className="columns"
                style={{
                  marginTop: "-10%",
                }}
              >
                <div className="column is-6"></div>
                <div className="column is-5">
                  <div className="content mb-6">
                    <p className="is-size-2 has-text-white title-font is-marginless">
                      Feels like you? Yeah, we feel you
                    </p>
                    <p className="has-text-white">
                      You can now active learn from the books you like
                    </p>
                    <Link to="/browse" className="button is-warning">
                      Try out one of our books
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="clip2"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-4">
              <img
                src={Phone}
                alt=""
                style={{ marginTop: "-240px" }}
                className="is-hidden-touch"
              />
            </div>

            <div className="column is-6">
              <p className="is-size-2 title-font has-text-weight-bold mb-6">
                How do we make learning from books easier?
              </p>
              <div className="columns is-multiline">
                <div className="column is-6 mb-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="is-24"
                  >
                    <path d="M 6 2 C 4.9057453 2 4 2.9057453 4 4 L 4 20 C 4 21.094255 4.9057453 22 6 22 L 18 22 C 19.094255 22 20 21.094255 20 20 L 20 8 L 14 2 L 6 2 z M 6 4 L 13 4 L 13 9 L 18 9 L 18 20 L 6 20 L 6 4 z M 10 11 L 10 17 L 15 14 L 10 11 z" />
                  </svg>
                  <p className="has-text-weight-bold my-3 is-size-5">
                    Engagement
                  </p>
                  <p>
                    You not only get text, but also remain engaged with
                    interactive and rich content.
                  </p>
                </div>
                <div className="column is-6 mb-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="is-24"
                  >
                    <path d="M 9 3 C 7.9069372 3 7 3.9069372 7 5 L 7 19 C 7 20.093063 7.9069372 21 9 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 9 3 z M 3 4 L 3 20 L 5 20 L 5 4 L 3 4 z M 9 5 L 19 5 L 19 19 L 9 19 L 9 5 z M 14 7 C 13.09268 7 12.285626 7.3807818 11.767578 7.9570312 C 11.24953 8.5332807 11 9.2676667 11 10 L 13 10 C 13 9.7323333 13.099658 9.4667193 13.255859 9.2929688 C 13.412061 9.1192182 13.60432 9 14 9 C 14.405657 9 14.599267 9.1177154 14.75 9.2832031 C 14.900733 9.4486909 15 9.7041124 15 10 C 15 10.565334 14.729421 10.903363 14.244141 11.462891 C 13.758861 12.022418 13 12.797755 13 14 L 15 14 C 15 13.665245 15.241139 13.366909 15.755859 12.773438 C 16.270579 12.179964 17 11.285666 17 10 C 17 9.2568876 16.752033 8.5122622 16.228516 7.9375 C 15.704999 7.3627378 14.897343 7 14 7 z M 13 15 L 13 17 L 15 17 L 15 15 L 13 15 z" />
                  </svg>
                  <p className="has-text-weight-bold my-3 is-size-5">
                    Interactivity
                  </p>
                  <p>
                    Actively participate in the process of learning by answering
                    to questions and prompts.
                  </p>
                </div>
                <div className="column is-6 mb-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="is-24"
                  >
                    <path d="M 18 3 A 2 2 0 0 0 16 5 A 2 2 0 0 0 18 7 A 2 2 0 0 0 20 5 A 2 2 0 0 0 18 3 z M 10.009766 3.8769531 L 5.5527344 6.1054688 L 6.4472656 7.8945312 L 9.9902344 6.1230469 L 12.052734 7.1992188 L 9.7753906 9.3710938 C 9.0336999 10.078302 8.8372651 11.105707 9.1347656 11.986328 L 3 18.585938 L 4.4140625 20 L 10.753906 13.660156 L 12.734375 14.890625 L 9.296875 18.289062 L 10.703125 19.710938 L 15.457031 15.011719 L 13.039062 11.962891 L 14.96875 10.160156 L 16.541016 13.304688 L 21.410156 11.119141 L 20.589844 9.2929688 L 17.470703 10.695312 L 15.494141 6.7421875 L 10.009766 3.8769531 z" />
                  </svg>
                  <p className="has-text-weight-bold my-3 is-size-5">
                    Exact steps to follow
                  </p>
                  <p>
                    Extract actions that you have to do in real life to apply
                    what you learn.
                  </p>
                </div>
                <div className="column is-6 mb-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="is-24"
                  >
                    <path d="M 9 3 C 5.134 3 2 5.686 2 9 C 2 10.127 2.369 11.177125 3 12.078125 L 3 17 L 6.2890625 14.533203 C 6.856243 14.737225 7.4643106 14.873056 8.0957031 14.943359 C 8.6249384 17.807777 11.50952 20 15 20 C 15.961 20 16.876938 19.833203 17.710938 19.533203 L 21 22 L 21 17.078125 C 21.631 16.177125 22 15.127 22 14 C 22 10.949595 19.341624 8.437958 15.904297 8.0566406 C 15.375062 5.1922234 12.49048 3 9 3 z M 9 5 C 11.757 5 14 6.794 14 9 C 14 11.206 11.757 13 9 13 C 8.293 13 7.60975 12.882391 6.96875 12.650391 L 5.953125 12.285156 L 5.0898438 12.931641 L 5 13 L 5 12.078125 L 5 11.447266 L 4.6386719 10.931641 C 4.2156719 10.327641 4 9.677 4 9 C 4 6.794 6.243 5 9 5 z M 15.880859 10.072266 C 18.217382 10.407299 20 12.035492 20 14 C 20 14.677 19.784328 15.326641 19.361328 15.931641 L 19 16.447266 L 19 17.078125 L 19 18 L 18.910156 17.933594 L 18.048828 17.285156 L 17.033203 17.652344 C 16.391203 17.883344 15.707 18 15 18 C 12.637965 18 10.664723 16.678599 10.144531 14.914062 C 13.055738 14.5025 15.357281 12.554984 15.880859 10.072266 z" />
                  </svg>
                  <p className="has-text-weight-bold my-3 is-size-5">
                    Community
                  </p>
                  <p>
                    Turn to the community to talk and debate about what you
                    learn with other readers just like you
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="is-relative mt-6">
        <WaveDetail
          style={{
            top: "-100px",
            bottom: "auto",
            transform: "rotate(180deg)",
          }}
        />
        <section className="section" style={{ backgroundColor: "#11183A" }}>
          <div className="container">
            <div className="columns">
              <div className="column is-12">
                <p className="is-size-3 has-text-white has-text-weight-bold title-font">
                  Books coming soon
                </p>
              </div>
            </div>
            <div className="columns is-multiline is-mobile">
              <div className="column is-1-fullhd is-1-widescreen is-2-desktop is-4-tablet is-4-mobile">
                <div>
                  <img src={Book1} alt="" />
                </div>
              </div>
              <div className="column is-1-fullhd is-1-widescreen is-2-desktop is-4-tablet is-4-mobile">
                <div>
                  <img src={Book2} alt="" />
                </div>
              </div>
              <div className="column is-1-fullhd is-1-widescreen is-2-desktop is-4-tablet is-4-mobile">
                <div>
                  <img src={Book3} alt="" />
                </div>
              </div>
              <div className="column is-1-fullhd is-1-widescreen is-2-desktop is-4-tablet is-4-mobile">
                <div>
                  <img src={Book4} alt="" />
                </div>
              </div>
              <div className="column is-2-desktop is-hidden-touch"></div>
              <div
                className="column is-4-desktop is-flex"
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className="field">
                  <p className="has-text-white">
                    Want to let you know when new books are available?
                  </p>
                </div>
                <div className="field has-addons ">
                  <div className="control" style={{ width: "100%" }}>
                    <input
                      type="email"
                      className="input is-fullwidth"
                      required=""
                      aria-required="true"
                      placeholder="Your email address"
                    />
                  </div>
                  <div className="control">
                    <button className="button is-primary">Get reminded</button>
                  </div>
                </div>
              </div>
            </div>
            {/* <ArticlesComponent
                    articles={data.allStrapiBook.edges}
                    defaultSort="newest"
                    showSort={true}
                  /> */}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Home

const StyledHeaderImg = styled.img`
  position: absolute;
  right: 0;
  @media (max-width: 1215px) {
    right: -35%;
  }
  @media (max-width: 769px) {
    right: 0;
    bottom: -80px;
    width: 240px;
  }
`
const WaveDetail = styled.div`
  clip-path: polygon(
    100% 0,
    0 0,
    0 77.5%,
    1% 77.4%,
    2% 77.1%,
    3% 76.6%,
    4% 75.9%,
    5% 75.05%,
    6% 74.05%,
    7% 72.95%,
    8% 71.75%,
    9% 70.55%,
    10% 69.3%,
    11% 68.05%,
    12% 66.9%,
    13% 65.8%,
    14% 64.8%,
    15% 64%,
    16% 63.35%,
    17% 62.85%,
    18% 62.6%,
    19% 62.5%,
    20% 62.65%,
    21% 63%,
    22% 63.5%,
    23% 64.2%,
    24% 65.1%,
    25% 66.1%,
    26% 67.2%,
    27% 68.4%,
    28% 69.65%,
    29% 70.9%,
    30% 72.15%,
    31% 73.3%,
    32% 74.35%,
    33% 75.3%,
    34% 76.1%,
    35% 76.75%,
    36% 77.2%,
    37% 77.45%,
    38% 77.5%,
    39% 77.3%,
    40% 76.95%,
    41% 76.4%,
    42% 75.65%,
    43% 74.75%,
    44% 73.75%,
    45% 72.6%,
    46% 71.4%,
    47% 70.15%,
    48% 68.9%,
    49% 67.7%,
    50% 66.55%,
    51% 65.5%,
    52% 64.55%,
    53% 63.75%,
    54% 63.15%,
    55% 62.75%,
    56% 62.55%,
    57% 62.5%,
    58% 62.7%,
    59% 63.1%,
    60% 63.7%,
    61% 64.45%,
    62% 65.4%,
    63% 66.45%,
    64% 67.6%,
    65% 68.8%,
    66% 70.05%,
    67% 71.3%,
    68% 72.5%,
    69% 73.6%,
    70% 74.65%,
    71% 75.55%,
    72% 76.35%,
    73% 76.9%,
    74% 77.3%,
    75% 77.5%,
    76% 77.45%,
    77% 77.25%,
    78% 76.8%,
    79% 76.2%,
    80% 75.4%,
    81% 74.45%,
    82% 73.4%,
    83% 72.25%,
    84% 71.05%,
    85% 69.8%,
    86% 68.55%,
    87% 67.35%,
    88% 66.2%,
    89% 65.2%,
    90% 64.3%,
    91% 63.55%,
    92% 63%,
    93% 62.65%,
    94% 62.5%,
    95% 62.55%,
    96% 62.8%,
    97% 63.3%,
    98% 63.9%,
    99% 64.75%,
    100% 65.7%
  );
  background-color: #11183a;
  height: 180px;
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
`
